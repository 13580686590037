/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { loadText } from 'api/requests';
import FormError from 'components/elements/formError';
import { useAppSelector } from 'customHooks';
import { Field, FormikProps } from 'formik';
import { FieldInputProps, FieldMetaProps } from 'formik/dist/types';
import { isEqual } from 'lodash-es';
import { FC, useEffect, useState } from 'react';
import Select, {
  GroupBase,
  SingleValue,
  SingleValueProps,
  components as reactSelectComponents
} from 'react-select';

import Icon from '../icon';
import InfoPopUp from '../infoPopup';
import DropdownArrow from './dropDownArrow/dropDownArrow';
import { OptionType, SelectInterface, SelectOptionProps } from './select.types';

const LabelOnlyValue = (props: SingleValueProps<OptionType, false, GroupBase<OptionType>>) => (
  <reactSelectComponents.SingleValue {...props}>
    {props.data.label}
  </reactSelectComponents.SingleValue>
);

const SelectOption: FC<SelectOptionProps> = ({
  name,
  label,
  optionList,
  formatOptionLabel,
  hideSelectedOptions = true,
  defaultValue,
  disabled,
  value,
  isSearchable = false,
  isClearable = false,
  components = {},
  placeholder,
  hasLabelTooltip = false,
  labelTooltipData,
  onChange,
  className,
  setIsMenuOpened,
  isOverlapping = true,
  showOnlyLabel = false
}) => {
  const [data, setData] = useState<SelectInterface>({ select: '', noOptions: '' });
  const store = useAppSelector((store) => store);

  // Store
  const { language } = store.userDetails.personalInfo.preferences;

  const filterOptions = (option: { label: string; value: string }, searchText: string) => {
    /* istanbul ignore if */
    if (!option || !option.label) {
      return false;
    }

    if (typeof searchText !== 'string') {
      return false;
    }

    return option.label.toString().toLowerCase().includes(searchText.toLowerCase());
  };

  const getCurrentValue = (fieldValue: FieldInputProps<never>) =>
    (optionList && optionList.find((el) => isEqual(el.value, value ? value : fieldValue))) ||
    defaultValue;

  const getOptionList = (fieldValue: FieldInputProps<never>) =>
    hideSelectedOptions
      ? optionList && optionList.filter((el) => el.value !== (value ? value : fieldValue))
      : optionList;

  const handleChange =
    (
      field: FieldInputProps<never>,
      setFieldValue: (name: string, value: string) => void,
      setFieldTouched: (name: string, type: boolean) => void
    ) =>
    (option: SingleValue<{ key?: number; label: string; value: string }>) => {
      if (option && field) {
        setFieldValue(field.name, option.value);
      }

      setTimeout(() => setFieldTouched(field?.name, true)); // hack for country

      onChange && onChange(option, field);
    };

  const handleBlur = (
    field: FieldInputProps<never>,
    setFieldTouched: (name: string, type: boolean) => void
  ) => setFieldTouched(field?.name, true);

  const handleMenuOpen = () => {
    setIsMenuOpened && setIsMenuOpened(true);
  };

  useEffect(() => {
    loadText('select', language).then((res) => setData(res?.data));
  }, [language]);

  return (
    <Field name={name}>
      {({
        field,
        meta,
        form: { setFieldValue, values, setFieldTouched }
      }: {
        field: FieldInputProps<never>;
        meta: FieldMetaProps<never>;
        form: FormikProps<never>;
      }) => (
        <div className={`form-select ${className}`} data-testid="select">
          {label && (
            <label htmlFor={`${name}-select`} id={label}>
              {label}
              {hasLabelTooltip ? (
                <span className="label-tooltip">
                  <InfoPopUp
                    title={labelTooltipData?.title}
                    position="top"
                    content={labelTooltipData?.content}
                  >
                    <div className="modal">
                      <Icon name="warranty-tooltip" />
                    </div>
                  </InfoPopUp>
                </span>
              ) : (
                <></>
              )}
            </label>
          )}
          <div className="form-select__inner" data-testid="select-field">
            <Select
              classNamePrefix="form-select"
              formatOptionLabel={formatOptionLabel}
              defaultValue={defaultValue}
              options={getOptionList(field?.value || values[name])}
              components={{
                ...(showOnlyLabel ? { SingleValue: LabelOnlyValue } : {}),
                DropdownIndicator: DropdownArrow,
                ...components
              }}
              value={field.value && getCurrentValue(field?.value || values[name])}
              onChange={handleChange(field, setFieldValue, setFieldTouched)}
              isDisabled={disabled}
              onMenuOpen={handleMenuOpen}
              onMenuClose={() => {
                handleBlur(field, setFieldTouched);
                setIsMenuOpened && setIsMenuOpened(false);
              }}
              isSearchable={isSearchable}
              isClearable={isClearable}
              placeholder={placeholder || data?.select}
              aria-labelledby={label}
              inputId={`${name}-select`}
              filterOption={filterOptions}
              styles={!isOverlapping ? { menu: (base) => ({ ...base, position: 'relative' }) } : {}}
              noOptionsMessage={() => data?.noOptions}
            />
          </div>
          <FormError meta={meta} />
        </div>
      )}
    </Field>
  );
};

export default SelectOption;
