/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import { isEmpty } from 'lodash-es';
import { ElectricHeating, Provider, RenewableGeneration, TariffValue } from 'types';

import { addOtherValueToEndOfList } from '../helpers';

export const DEFAULT_COUNTRY = '**';
export const OTHER_VALUE = 'Other';
export const ENTSOE = 'ENTSOE';

const sortOptions = (options: TariffValue[]): TariffValue[] => {
  if (!options || !Array.isArray(options)) {
    return [];
  }

  return [...options].sort((a, b) => a.label.localeCompare(b.label));
};

export const checkIfEconomy = (currentProvider?: Provider) =>
  currentProvider ? currentProvider?.isEconomy.toLowerCase() === 'true' : false;
export const checkIfFlexible = (currentProvider?: Provider) =>
  currentProvider ? currentProvider?.isFlexible.toLowerCase() === 'true' : false;

export const getElectricHeatingOptions = (data: ElectricHeating) => [
  {
    name: 'airSourceHeatPump',
    id: 'airSourceHeatPump',
    label: data?.airSourceHeatPump
  },
  {
    name: 'groundSourceHeatPump',
    id: 'groundSourceHeatPump',
    label: data?.groundSourceHeatPump
  },
  {
    name: 'electricStorageHeating',
    id: 'electricStorageHeating',
    label: data?.electricStorageHeating
  },
  {
    name: 'other_electricHeating',
    id: 'other_electricHeating',
    label: data?.other
  }
];

export const getRenewableGenerationOptions = (data: RenewableGeneration) => [
  {
    name: 'solarPanels',
    id: 'solarPanels',
    label: data?.solarPanels
  },
  {
    name: 'windTurbine',
    id: 'windTurbine',
    label: data?.windTurbine
  },
  {
    name: 'hydroelectric',
    id: 'hydroelectric',
    label: data?.hydroelectric
  },
  {
    name: 'other_renewableGeneration',
    id: 'other_renewableGeneration',
    label: data?.other
  }
];

export const getEnergyProvidersList = (
  countryCode: string = DEFAULT_COUNTRY,
  tariffs: any
): TariffValue[] => {
  const providersList = tariffs?.providers;
  const providersListForCountry = !isEmpty(providersList?.[countryCode])
    ? providersList?.[countryCode]
    : providersList?.[DEFAULT_COUNTRY];

  return addOtherValueToEndOfList(providersListForCountry);
};
export const getEnergyTariff = (
  provider: string = OTHER_VALUE,
  countryCode: string = DEFAULT_COUNTRY,
  tariffsListValue: TariffValue[]
): TariffValue[] => {
  if (provider === ENTSOE) {
    return tariffsListValue?.filter((tarriff: TariffValue) => tarriff.label.includes(countryCode));
  } else if (provider === OTHER_VALUE) {
    if (isEmpty(countryCode)) {
      return addOtherValueToEndOfList(
        tariffsListValue.filter((obj) => obj.country === '**' || isEmpty(obj.country))
      );
    } else {
      const tariffFiltered = tariffsListValue.some((x) => x.country === countryCode)
        ? tariffsListValue.filter((x) => x.country === countryCode)
        : tariffsListValue.filter((x) => x.country === '**');
      return addOtherValueToEndOfList(tariffFiltered);
    }
  }

  const tariffsByCountry = tariffsListValue?.filter((obj) => obj.country === countryCode);
  const sortedTariffs = sortOptions(tariffsByCountry);

  return addOtherValueToEndOfList(sortedTariffs);
};

export const resetEnergyTariff = (
  setFieldValue: {
    (field: string, value: any, shouldValidate?: boolean | undefined): void;
    (arg0: string, arg1: string): void;
  },
  setFieldTouched: {
    (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined): void;
    (arg0: string, arg1: boolean): void;
  }
) => {
  setFieldValue('energyTariff', '');
  setFieldTouched('energyTariff', false);
};

export const electriHeatingRadioOptions = [
  { key: 'airSourceHeatPump', value: 'airSourceHeatPump' },
  { key: 'groundSourceHeatPump', value: 'groundSourceHeatPump' },
  { key: 'electricStorageHeating', value: 'electricStorageHeating' },
  { key: 'otherElectricHeating', value: 'other_electricHeating' }
];

export const renewableGenerationRadioOptions = [
  { key: 'solarPanels', value: 'solarPanels' },
  { key: 'windTurbine', value: 'windTurbine' },
  { key: 'hydroelectric', value: 'hydroelectric' },
  { key: 'otherGeneration', value: 'other_renewableGeneration' }
];

export const AddEditLocationInitialValues = {
  addressLabel: 'test1',
  cancelBtnText: '',
  electricHeating: {
    airSourceHeatPump: '',
    electricStorageHeating: '',
    groundSourceHeatPump: '',
    other: ''
  },
  electricHeatingLabel: '',
  energySupplierLabel: '',
  energyTariffLabel: '',
  errorMessage: '',
  heatingDescription: '',
  maxPowerOutputDescr: '',
  maxPowerOutputLabel: '',
  nicknameLabel: '',
  otherElectricHeating: '',
  otherRenewableGeneration: '',
  renewableGeneration: { hydroelectric: '', other: '', solarPanels: '', windTurbine: '' },
  renewableGenerationLabel: '',
  submitBtnText: '',
  submitBtnTextAdd: '',
  submitBtnTextSave: '',
  success: '',
  swedenEnergyTariffLabel: '',
  tariffExpirationDatePickerCancelBtn: '',
  tariffExpirationDatePickerLabel: '',
  tariffExpirationDatePickerSubmitBtn: '',
  tariffExpirationDatePickerTitle: '',
  validation: {
    addressLine1: '',
    city: '',
    country: '',
    energySupplier: '',
    energyTariff: '',
    tariffFriendlyName: '',
    fullAddress: '',
    nicknameDuplicate: '',
    nicknameRequired: '',
    postalCode: '',
    region: '',
    nicknameMax: ''
  },
  extendedWarrantyWarning: {
    title: '',
    description: '',
    link: '',
    cancelBtn: '',
    confirmBtn: ''
  },
  libbiTariffWarning: {
    title: '',
    description: '',
    cancelBtn: '',
    confirmBtn: ''
  }
};

export const mockStore = {
  userDetails: {
    personalInfo: {
      preferences: {
        currency: 'EUR',
        language: 'en-GB'
      },
      contactAddress: {}
    }
  },
  tariffs: {
    tariffs: {
      'British Gas': [
        {
          label: 'Dual Rate (eg economy 7)',
          value: '9df99a52-2fc7-ed11-abf4-06314c754d36',
          country: 'GB'
        }
      ],
      ENTSOE: [
        {
          label: 'BZN|SE1',
          value: '38fd9a52-2fc7-ed11-abf4-06314c754d36',
          country: 'SE'
        }
      ],
      Other: [
        {
          label: 'Dual Rate (eg economy 7)',
          value: '4dfa9a52-2fc7-ed11-abf4-06314c754d36',
          country: 'GB'
        },
        { label: 'Other', value: '37fd9a52-2fc7-ed11-abf4-06314c754d36', country: '**' }
      ]
    },
    providers: {
      GB: [
        {
          label: 'Boost Energy',
          value: 'Boost Energy'
        },
        {
          label: 'British Gas',
          value: 'British Gas'
        },
        { label: 'Other', value: 'Other' }
      ],
      SE: [
        {
          label: 'ENTSOE',
          value: 'ENTSOE'
        }
      ],
      '**': [
        {
          label: 'Other',
          value: 'Other'
        }
      ]
    },
    providerList: [
      {
        energyProvider: 'ENSO Energie Sachsen Ost',
        tariffName: 'Dual Rate (eg economy 7)',
        isFlexible: 'True',
        isGreen: 'False',
        isEV: 'False',
        isEconomy: 'True',
        isEntsoe: 'False',
        country: 'GB',
        internalReference: 'DE-0220-002',
        guid: 'f0f056d0-6f62-ec11-8289-060ba7111d4a'
      },
      {
        energyProvider: 'ENTSOE',
        tariffName: 'BZN|SE1',
        isFlexible: 'True',
        isGreen: 'False',
        isEV: 'False',
        isEconomy: 'True',
        isEntsoe: 'True',
        country: 'SE',
        internalReference: 'DE-0220-002',
        guid: '38fd9a52-2fc7-ed11-abf4-06314c754d36'
      }
    ]
  },
  location: {
    activeLocation: {
      invitationData: {
        invitationId: '123'
      },
      energySetup: {
        id: '111223322111',
        isFlexible: 'True'
      }
    },
    locations: [
      {
        address: {},
        energySetup: {
          id: '78a115b6-07d2-ed11-abf4-06314c754d37',
          isFlexible: 'True'
        }
      },
      {
        address: {},
        energySetup: {
          id: '78a115b6-07d2-ed11-abf4-06314c754d36',
          isFlexible: 'True'
        }
      }
    ]
  },
  products: {
    devicesList: [{ locationId: '111223322111', isExtendedWarranty: true }],
    offlineProducts: []
  }
};
