/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/

export const TariffOptionLabel = ({ label, name }: { label: string; name?: string }) => {
  if (!label) return null;
  const showName = name && name !== label && !label.toLowerCase().includes(name.toLowerCase());

  return (
    <div data-testid="tariff-option-label">
      <div>{label}</div>
      {showName && <div>{`(${name})`}</div>}
    </div>
  );
};
