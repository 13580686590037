/*************************
 * @license
 * Copyright 2024 Myenergi Ltd. All rights reserved.
 * No part of this work may be reproduced, stored in a retrieval system of any nature, or transmitted, in any form or by any means without the prior written permission of Myenergi Ltd., the copyright owner.
 * If any unauthorised acts are carried out in relation to this copyright work, a civil claim for damages may be made and/or a criminal prosecution may result.
 *************************/
import {
  getEnergyProviders,
  getUserEnergySetup,
  getUserHubsAndDevices,
  saveEnergySetup,
  saveNewEnergySetup
} from 'api/methods';
import { loadText } from 'api/requests';
import { useGetData } from 'api/storeHooks';
import AddressLookup from 'components/elements/address';
import { ADDRESS_FIELDS } from 'components/elements/address/constants';
import Button from 'components/elements/button';
import { BUTTON_COLORS, BUTTON_TYPE } from 'components/elements/button/consts';
import DatePicker from 'components/elements/datepicker';
import FormSeparator from 'components/elements/formSeparator';
import MultiSelect from 'components/elements/multiselect';
import SelectOption from 'components/elements/select';
import Textbox from 'components/elements/textbox';
import Popup from 'components/popup';
import { DeviceType } from 'constants/DeviceType';
import {
  processDeviceList,
  processProviders,
  setActiveLocation,
  setActiveLocationId,
  setAllTariffs,
  setDevicesAndHubList,
  setError,
  setFormErrorMessage,
  setLoading,
  setLocations,
  setNotification,
  updateLocation
} from 'containers/appStore';
import { useAppDispatch, useAppSelector } from 'customHooks';
import { Form, Formik } from 'formik';
import { isEmpty, isEqual } from 'lodash-es';
import { FC, useEffect, useState } from 'react';
import {
  DATE_FORMATS,
  dateFormated,
  EMPTY_LOCATION,
  EXTENDED_WARRANTY_WARNING,
  extractFields,
  findActiveLocation,
  FL,
  LIBBI_TARIFF_SETUP_WARNING,
  processAddress,
  trimValues,
  VALIDATION_RULES
} from 'shared/js';
import {
  EnergySetup,
  ILocationFormValues,
  ILocationPayload,
  SaveLocationApiResponse,
  TariffValue
} from 'types';
import dayjs from 'utils/dayjs';
import * as yup from 'yup';

import ExtendedWarrantyWarning from '../extendedWarranty';
import LibbiTariffWarning from '../libbi/libbiTariffWarning';
import { IAddLocationData, IAddLocationProps, IKeyValue } from './addEditLocation.types';
import {
  AddEditLocationInitialValues,
  checkIfEconomy,
  checkIfFlexible,
  electriHeatingRadioOptions,
  ENTSOE,
  getElectricHeatingOptions,
  getEnergyProvidersList,
  getEnergyTariff,
  getRenewableGenerationOptions,
  renewableGenerationRadioOptions,
  resetEnergyTariff
} from './helpers';
import { TariffOptionLabel } from './tariffOption/tariffOptionLabel';

const validCountryCodes = ['GB', 'IE'];

const AddEditLocation: FC<IAddLocationProps> = ({
  onClose,
  location = EMPTY_LOCATION,
  editLocation,
  setBoardNotif
}) => {
  const store = useAppSelector((store) => store);
  const dispatch = useAppDispatch();
  const [data, setData] = useState<IAddLocationData>(AddEditLocationInitialValues);
  const [popupAlert, setPopupAlert] = useState<{ [key: string]: boolean }>({
    [EXTENDED_WARRANTY_WARNING]: false,
    [LIBBI_TARIFF_SETUP_WARNING]: false
  });
  const [bothPopups, setBothPopups] = useState(false);
  const [hasCountryError, setHasCountryError] = useState(false);

  const { userDetails, tariffs, products, site } = store;
  const { locations, activeLocation } = store?.location;
  const { language, currency } = userDetails.personalInfo.preferences;
  const { contactAddress } = userDetails.personalInfo;
  const invitationId = store?.location?.activeLocation?.invitationData?.invitationId;
  const formErrorMessage = site.formErrorMessage;
  const tariffExpStart = dayjs().format('YYYY');
  const tariffExpEnd = dayjs(tariffExpStart).add(10, 'years').format('YYYY');
  const tariffDataReady = useGetData(
    getEnergyProviders,
    processProviders,
    setAllTariffs,
    data.errorMessage
  );
  const allDevices = [...products.offlineProducts, ...products.devicesList];
  const isCountryEligibleForEW =
    location?.address?.countryCode && validCountryCodes.includes(location.address.countryCode);
  const hasDevicesWithEW = allDevices
    .filter((device) => device?.locationId === activeLocation.energySetup.id)
    .some((device) => device?.isExtendedWarranty);
  const showWarrantyPopup = hasDevicesWithEW && isCountryEligibleForEW;

  const { devicesList } = products;
  const libbiDevices = devicesList.filter((device) => device?.deviceType === DeviceType.Libbi);
  const hasLibbiDevices = libbiDevices.length >= 1;

  const generalPopupCondition = showWarrantyPopup && hasLibbiDevices;

  const handleCancel = () => {
    onClose && onClose();
    return;
  };

  const computeRadioValues = (keyValuePairArr: IKeyValue[] = []) => {
    const arr: string[] = [];

    keyValuePairArr.forEach(({ key, value }) => {
      if (location?.energySetup?.[key as keyof EnergySetup]) {
        arr.push(value);
      }
    });

    return arr;
  };

  const getCurrentEnergyProvider = (id: string) =>
    tariffs?.providerList?.find((t) => t.guid === id);

  const getInitialValues = (): ILocationFormValues => {
    const energyProvider = getCurrentEnergyProvider(location?.energySetup?.energyProviderId);
    const address = location?.address;

    const momentExpirationDate = location?.energySetup?.tariffExpirationDate
      ? dayjs(location?.energySetup?.tariffExpirationDate)
      : undefined;

    return {
      nickname: location?.address?.siteName || '',
      electricHeating: computeRadioValues(electriHeatingRadioOptions),
      renewableGeneration: computeRadioValues(renewableGenerationRadioOptions),
      otherElectricHeating: location?.energySetup?.otherElectricHeating,
      otherRenewableGeneration: location?.energySetup?.otherGeneration,
      address: {
        [ADDRESS_FIELDS.fullAddress]: processAddress(address),
        [ADDRESS_FIELDS.line1]: address?.addressLine1,
        [ADDRESS_FIELDS.line2]: address?.addressLine2,
        [ADDRESS_FIELDS.city]: address?.city,
        [ADDRESS_FIELDS.postalCode]: address?.postalCode,
        [ADDRESS_FIELDS.country]: address?.country,
        [ADDRESS_FIELDS.countryCode]: address?.countryCode,
        [ADDRESS_FIELDS.region]: address?.region
      },
      energySupplier: energyProvider?.energyProvider || '',
      energyTariff: energyProvider?.guid || '',
      tariffExpirationDate: momentExpirationDate,
      maxPowerOutput: location?.energySetup?.maxPowerOutput || 0
    } as ILocationFormValues;
  };

  const getValidationSchema = () => {
    const validation = data?.validation;
    const nicknameList = store?.location.locations
      ?.filter((loc) => location?.energySetup?.id !== loc?.energySetup?.id)
      .map((loc) => (loc?.address?.siteName ? loc?.address?.siteName : ''));

    return yup.object().shape({
      nickname: yup
        .string()
        .trim()
        .max(20, validation?.nicknameMax)
        .required(validation?.nicknameRequired)
        .test(
          'nickname',
          validation?.nicknameDuplicate,
          (value) => !!value && nicknameList.indexOf(value) === -1
        ),
      address: yup.object().shape({
        [ADDRESS_FIELDS.fullAddress]: yup
          .string()
          .trim()
          .matches(VALIDATION_RULES.NO_SPECIAL_CHAR, validation?.fullAddress),
        [ADDRESS_FIELDS.line1]: yup
          .string()
          .trim()
          .required(validation?.addressLine1)
          .matches(VALIDATION_RULES.NO_SPECIAL_CHAR, validation?.addressLine1),
        [ADDRESS_FIELDS.city]: yup
          .string()
          .trim()
          .required(validation?.city)
          .matches(VALIDATION_RULES.NO_SPECIAL_CHAR, validation?.city),
        [ADDRESS_FIELDS.postalCode]: yup.string().trim().required(validation?.postalCode),
        [ADDRESS_FIELDS.country]: yup.string().required(validation?.country),
        [ADDRESS_FIELDS.region]: yup.string().required(validation?.region)
      }),
      energySupplier: yup.string().required(validation?.energySupplier),
      energyTariff: yup.string().required(validation?.energyTariff)
    });
  };

  const resetEnergyFields = (
    setFieldValue: (field: string, value: string) => void,
    setFieldTouched: (field: string, isTouched?: boolean | undefined) => void,
    setSubmitting: (value: boolean) => void
  ) => {
    setFieldValue('energyTariff', '');
    setFieldTouched('energyTariff', false);
    setFieldValue('energySupplier', '');
    setFieldTouched('energySupplier', false);
    dispatch(setFormErrorMessage(''));
    setSubmitting(false);
  };

  const resolveUpdate = (res: SaveLocationApiResponse) => {
    if (!res.status || res.statusCode >= 300) {
      dispatch(setLoading(false));
      dispatch(setFormErrorMessage(res?.message || data?.errorMessage));
      return;
    }

    dispatch(setNotification(data?.success));
    dispatch(setFormErrorMessage(''));
    dispatch(setLoading(false));
    handleCancel();

    const isNewLocation = !locations.some(
      (location) => location?.energySetup?.id === res?.content?.energySetup?.id
    );

    if (isNewLocation) {
      dispatch(setLocations([...locations, res?.content]));
      const getActiveLocation = findActiveLocation(
        [...locations, res?.content],
        res?.content?.energySetup?.id
      );
      if (getActiveLocation) {
        dispatch(setActiveLocation(getActiveLocation));
        dispatch(setActiveLocationId(getActiveLocation?.energySetup?.id));
      }
    } else {
      getUserHubsAndDevices({ invitationId }).then((res) => {
        if (!res.status || res.statusCode >= 300) {
          console.error(res);
          return;
        }
        dispatch(setDevicesAndHubList(processDeviceList(res?.content)));
      });

      getUserEnergySetup().then((res) => {
        if (!res?.status || res?.statusCode >= 300) {
          dispatch(setError(res?.message || data.errorMessage));
          return;
        }
        dispatch(setLocations(res?.content));
      });

      dispatch(updateLocation(res?.content));
      dispatch(setActiveLocationId(res?.content?.energySetup?.id));
    }
    if (typeof localStorage !== 'undefined') {
      localStorage.setItem('activeLocation', res?.content?.energySetup?.id);
    }
  };

  async function handleSubmit(values: ILocationFormValues) {
    dispatch(setLoading(true));
    const newValues = trimValues(values);
    newValues.region = newValues?.region || (contactAddress?.region as string);

    const {
      address,
      nickname,
      energyTariff,
      otherElectricHeating,
      otherRenewableGeneration,
      tariffExpirationDate,
      maxPowerOutput,
      electricHeating,
      renewableGeneration,
      region
    } = newValues;

    const { addressLine1, addressLine2, city, postalCode, country, countryCode } = address;
    const currentProvider = getCurrentEnergyProvider(energyTariff);

    const locationForPost: ILocationPayload = {
      address: {
        addressLine1,
        addressLine2: addressLine2,
        city,
        country,
        countryCode,
        postalCode,
        region: values?.address?.region || region,
        siteName: nickname,
        currency,
        shouldValidateAddress: true
      },
      energySetup: {
        airSourceHeatPump: electricHeating?.includes('airSourceHeatPump'),
        electricStorageHeating: electricHeating?.includes('electricStorageHeating'),
        energyProviderId: energyTariff,
        groundSourceHeatPump: electricHeating?.includes('groundSourceHeatPump'),
        hydroelectric: renewableGeneration?.includes('hydroelectric'),
        isEconomy: checkIfEconomy(currentProvider),
        maxPowerOutput: maxPowerOutput ? parseFloat(maxPowerOutput) : undefined,
        otherElectricHeating: electricHeating?.includes('other_electricHeating')
          ? otherElectricHeating
          : '',
        otherGeneration: renewableGeneration?.includes('other_renewableGeneration')
          ? otherRenewableGeneration
          : '',
        solarPanels: renewableGeneration?.includes('solarPanels'),
        tariffExpirationDate: !isEmpty(tariffExpirationDate)
          ? tariffExpirationDate?.format(dateFormated(DATE_FORMATS.DD_MM_YYYY, FL.SERVER))
          : '',
        windTurbine: renewableGeneration?.includes('windTurbine'),
        energyProvider: currentProvider?.energyProvider,
        energyTariff: currentProvider?.tariffName
      }
    };

    if (location?.address?.id) {
      locationForPost.address.id = location?.address?.id;
      locationForPost.energySetup.id = location?.energySetup?.id;

      if (isEqual(extractFields(location.address), extractFields(locationForPost.address))) {
        locationForPost.address.shouldValidateAddress = false;
      }
    }

    if (location && editLocation) {
      await saveEnergySetup(locationForPost, { invitationId }).then(resolveUpdate);
    } else {
      await saveNewEnergySetup(locationForPost).then(resolveUpdate);
    }

    dispatch(setLoading(false));
    if (isEmpty(locationForPost?.address?.id) && setBoardNotif) {
      setBoardNotif(true);
    }
  }

  const handleOnSubmit = (values: ILocationFormValues) => {
    const selectedEnergyProvdier = getCurrentEnergyProvider(values?.energyTariff);
    const isSelectedTariffFlexible = checkIfFlexible(selectedEnergyProvdier);

    const conditionsMap = {
      showBothWarnings:
        generalPopupCondition &&
        !validCountryCodes.includes(values?.address?.countryCode) &&
        isSelectedTariffFlexible,
      showWarrantyWarning:
        showWarrantyPopup && !validCountryCodes.includes(values?.address?.countryCode),
      showTariffWarning: hasLibbiDevices && isSelectedTariffFlexible && editLocation
    };

    let selectedCondition = null;

    switch (true) {
      case conditionsMap.showBothWarnings:
        setBothPopups(true);
        selectedCondition = EXTENDED_WARRANTY_WARNING;
        break;

      case conditionsMap.showWarrantyWarning:
        selectedCondition = EXTENDED_WARRANTY_WARNING;
        break;

      case conditionsMap.showTariffWarning:
        selectedCondition = LIBBI_TARIFF_SETUP_WARNING;
        break;

      default:
        handleSubmit(values);
    }

    setPopupAlert(selectedCondition ? { [selectedCondition]: true } : {});
  };

  const getEnergyTariffLabel = (energySupplier: string) =>
    energySupplier === ENTSOE ? data?.swedenEnergyTariffLabel : data?.energyTariffLabel;

  useEffect(() => {
    loadText('addeditlocationform', language).then((res) => setData(res?.data));
  }, [language]);

  if (!tariffDataReady) return <></>;

  return (
    <div className="add-edit-location" data-testid="add-edit-location-form">
      <Formik
        initialValues={getInitialValues()}
        enableReinitialize
        onSubmit={handleOnSubmit}
        validationSchema={getValidationSchema()}
      >
        {({ values, setFieldValue, setFieldTouched, isSubmitting, setSubmitting }) => {
          const { address, energySupplier, electricHeating, renewableGeneration } = values;
          const { countryCode } = address;
          const tariffProvider = tariffs?.tariffs?.[energySupplier] as TariffValue[];

          return (
            <Form>
              <Textbox name="nickname" id="nickname" label={data?.nicknameLabel} />

              <AddressLookup
                label={data?.addressLabel}
                onCountryChange={() =>
                  resetEnergyFields(setFieldValue, setFieldTouched, setSubmitting)
                }
                setHasCountryError={setHasCountryError}
              />

              <div className="add-edit-location__select--select">
                <SelectOption
                  name="energySupplier"
                  isSearchable
                  optionList={getEnergyProvidersList(countryCode, tariffs)}
                  label={data?.energySupplierLabel}
                  onChange={() => resetEnergyTariff(setFieldValue, setFieldTouched)}
                />
                <SelectOption
                  name="energyTariff"
                  isSearchable
                  optionList={getEnergyTariff(energySupplier, countryCode, tariffProvider)}
                  label={getEnergyTariffLabel(energySupplier)}
                  formatOptionLabel={(option) => TariffOptionLabel(option)}
                  showOnlyLabel={true}
                />
              </div>

              <DatePicker
                id="tariffExpirationDate"
                name="tariffExpirationDate"
                label={data.tariffExpirationDatePickerLabel}
                calendarYearStart={tariffExpEnd}
                calendarYearEnd={tariffExpStart}
              />

              <FormSeparator />

              <div className="add-edit-location__heating--description">
                {data.heatingDescription}
              </div>

              <MultiSelect
                options={getElectricHeatingOptions(data.electricHeating)}
                name="electricHeating"
                label={data?.electricHeatingLabel}
              />

              {electricHeating?.includes('other_electricHeating') && (
                <Textbox
                  name="otherElectricHeating"
                  id="otherElectricHeating"
                  label={data?.otherElectricHeating}
                  autoFocus
                />
              )}

              <MultiSelect
                options={getRenewableGenerationOptions(data?.renewableGeneration)}
                name="renewableGeneration"
                label={data?.renewableGenerationLabel}
              />

              {renewableGeneration?.includes('other_renewableGeneration') && (
                <Textbox
                  name="otherRenewableGeneration"
                  id="otherRenewableGeneration"
                  label={data?.otherRenewableGeneration}
                  autoFocus
                />
              )}

              <div className="add-edit-location__textbox--description">
                <Textbox
                  name="maxPowerOutput"
                  id="maxPowerOutput"
                  label={data?.maxPowerOutputLabel}
                  className="add-edit-location__fixed-width"
                />
                <span>{data?.maxPowerOutputDescr}</span>
              </div>

              <FormSeparator />
              {formErrorMessage && <div className="form-error-message">{formErrorMessage}</div>}
              <div className="add-edit-location__buttons">
                <Button
                  className={BUTTON_COLORS.green}
                  label={data?.cancelBtnText}
                  onClick={handleCancel}
                />
                <Button
                  className={`${BUTTON_COLORS.green} ${BUTTON_COLORS.filled}`}
                  type={BUTTON_TYPE.SUBMIT}
                  isDisabled={isSubmitting || hasCountryError}
                  label={editLocation ? data?.submitBtnTextSave : data?.submitBtnTextAdd}
                />
              </div>

              <Popup
                title={data.extendedWarrantyWarning.title}
                render={ExtendedWarrantyWarning}
                condition={popupAlert}
                setter={setPopupAlert}
                name={EXTENDED_WARRANTY_WARNING}
                className="extended-warranty-warning__popup"
                passProps={{
                  data: data.extendedWarrantyWarning,
                  language,
                  handleSubmit,
                  values,
                  setSubmitting,
                  bothPopups,
                  setPopupAlert
                }}
                shouldCloseOnOverlayClick={false}
              />

              <Popup
                title={data.libbiTariffWarning.title}
                render={LibbiTariffWarning}
                condition={popupAlert}
                setter={setPopupAlert}
                name={LIBBI_TARIFF_SETUP_WARNING}
                className="libbi-tariff-warning__popup"
                passProps={{ data: data.libbiTariffWarning, handleSubmit, values, setSubmitting }}
                shouldCloseOnOverlayClick={false}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddEditLocation;
